import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import logo from "../../assets/images/logo.jpeg";
import BrochurePDF from "../../BROCHURE.pdf";
import "./style.css";

const Header = () => {
  return (
    <header className="header">
      <div className="fixed-header">
        <Navbar expand="lg" bg="#374f5a" variant="#374f5a">
          <div className="container">
            <Navbar.Brand href="/">
              <img
                src={logo}
                alt="North View Symphony"
                className="custom-logo"
                style={{ width: 100, height: 35 }}
              />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="navbarNav"
              className="custom-toggler"
            />

            <Navbar.Collapse id="navbarNav">
              <Nav className="ml-auto">
                <Nav.Item>
                  <Nav.Link href="#home" style={{ color: "white" }}>
                    <Icon.HouseDoorFill /> <span>HOME</span>
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link href="#price" style={{ color: "white" }}>
                    <Icon.GraphUpArrow /> <span>AREA & PRICING</span>
                  </Nav.Link>
                </Nav.Item> */}
                <Nav.Item>
                  <Nav.Link href="#floorplan" style={{ color: "white" }}>
                    <Icon.ListColumnsReverse /> <span>FLOOR PLAN</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="#amenities" style={{ color: "white" }}>
                    <Icon.TreeFill /> <span>AMENITIES</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="#location" style={{ color: "white" }}>
                    <Icon.CursorFill /> <span>LOCATION</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href={BrochurePDF} style={{ color: "white" }}>
                    <Icon.FileEarmarkPdf /> <span>E-BROCHURE</span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </div>
    </header>
  );
};

export default Header;
